// https://codepen.io/hexagoncircle/pen/eYMrGwW
.marquee {
  --gap: 1rem;
  display: flex;
  overflow: hidden;
  user-select: none;
  min-height: 40px;
  gap: var(--gap);
  background-color: black;
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  color: white;
  //   justify-content: space-around;
  min-width: 100%;

  animation: scroll 120s linear infinite;
  gap: var(--gap);
}
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

// .marquee__content > * {
//   flex: 0 0 auto;
//   color: white;
//   background: dodgerblue;
//   margin: 2px;
//   padding: 1rem 2rem;
//   border-radius: 0.25rem;
//   text-align: center;
// }

// .marquee {
//   border: 2px dashed lightgray;
// }
//marque wll p
