.curve {
  height: 100vh;
  svg {
    position: fixed;
    width: 100vw;
    height: calc(100vh + 600px);
    top: -300px;
    left: 0;
    pointer-events: none;
  }
}
// .text {
//   position: absolute;
//   left: 50%;
//   top: 40%;

//   z-index: 3;
// }
